import { FC } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const contentByLanguage = {
  ru: {
    heading: 'Анализируем и исследуем',
    description:
      'Проанализируем ваши товары, расскажем всё о конкурентах, подберём нишу, автоматизируем процессы',
  },
  en: {
    heading: 'We analyze and research',
    description:
      'We can analyze your products, tell everything about the competition, find a niche, automate processes',
  },
} as const

const SellematicsHero: FC = () => {
  const { t, lang } = useIntl()
  const content = contentByLanguage[lang as 'ru' | 'en']

  return (
    <div className="relative px-6 isolate lg:px-8">
      <div
        className="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:-top-60"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff5a39] to-[#fcc63c] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="max-w-4xl py-32 mx-auto">
        {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative px-3 py-1 text-sm leading-6 text-gray-600 rounded-full ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            Announcing our next round of funding.{' '}
            <a href="#" className="font-semibold text-indigo-600">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div> */}
        <div className="text-center">
          <h1 className="text-5xl font-semibold text-gray-900 sm:text-7xl [font-variation-settings:'wdth'_113,'wght'_650] tracking-[-0.01em]">
            {content.heading}{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r selection:text-black from-[#ff102e] supports-[color:oklch(0_0_0)]:from-[oklch(67.3%_0.266_25.039656026515278)] to-[#fbca00] supports-[color:oklch(0_0_0)]:to-[oklch(85.82%_0.201_91.19)]">
              e&#8288;-&#8288;commerce
            </span>
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 [text-wrap:balance]">
            {content.description}
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <a
              href="#access"
              className="px-4 py-3 hover:text-black [box-shadow:0_0_48px_5px_#fe6c14] supports-[color:oklch(0_0_0)]:[box-shadow:0_0_48px_5px_oklch(71.76%_0.229_44.47)] text-base font-semibold bg-black text-white rounded-md hover:bg-gradient-to-br from-[#ff102e] supports-[color:oklch(0_0_0)]:from-[oklch(67.3%_0.266_25.039656026515278)] to-[#fbca00] supports-[color:oklch(0_0_0)]:to-[oklch(85.82%_0.201_91.19)] hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
            >
              {t`landing.request_demo`}
            </a>
            {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Learn more <span aria-hidden="true">→</span>
            </a> */}
          </div>
        </div>
      </div>
      <div
        className="absolute hidden sm:block inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-40rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff5a39] to-[#fcc63c] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  )
}

export default SellematicsHero
