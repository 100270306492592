import { useToast } from 'components/app/NotificationArea'
import Button from 'components/base/Button'
import FormItem from 'components/base/FormItem'
import Input from 'components/base/Input'
import Container from 'components/landing/Container'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { CompanyContactRequest } from 'lib/dto'
import { useIntl } from 'lib/intl-utils'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

const contentByLang = {
  ru: {
    heading: 'Давайте обсудим сотрудничество',
    description: 'Расскажем, как мы можем помочь вашей компании',
  },
  en: {
    heading: "Let's work together",
    description: "Let's discuss how we can help your company",
  },
} as const

const DemoContactForm: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const { executeRecaptcha, loaded: recaptchaLoaded } = useReCaptcha()
  const showToast = useToast()
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<CompanyContactRequest>({ mode: 'onSubmit' })
  const formRef = useRef<HTMLFormElement>(null)

  const content = contentByLang[lang as 'en' | 'ru']

  const onDemoFormSubmit = useCallback(
    async (data: CompanyContactRequest) => {
      if (!recaptchaLoaded) return

      const recaptchaToken = await executeRecaptcha('register')
      const response = await ctx.requestDemo(data)

      if (response.status === 'success') {
        showToast('success', t`landing.we_will_contact_you`)
        reset()
        return
      }
      showToast('error', t`landing.something_went_wrong`)
    },
    [executeRecaptcha, recaptchaLoaded]
  )

  useEffect(() => {
    const form = formRef.current

    if (!form) return

    form.style.setProperty('--gradient-angle', '0')

    const listener = (event: MouseEvent) => {
      const rect = form.getBoundingClientRect()
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2
      const dx = event.clientX - centerX
      const dy = event.clientY - centerY

      const angle = Math.atan2(dy, dx) - Math.PI / 2

      form.style.setProperty('--gradient-angle', `${angle}rad`)
    }

    document.addEventListener('mousemove', listener)

    return () => document.removeEventListener('mousemove', listener)
  }, [formRef.current])

  return (
    <Container className="relative sm:!pb-36 !pt-12">
      <a id="access" className="absolute -translate-y-8" />
      <div className="mx-auto max-w-xl lg:max-w-5xl">
        <h2 className="text-4xl font-semibold tracking-tight text-gray-900">{content.heading}</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">{content.description}</p>
        <div className="flex flex-col gap-16 mt-16 sm:gap-y-20 lg:flex-row">
          <form
            ref={formRef}
            onSubmit={handleSubmit(onDemoFormSubmit)}
            className="px-6 sm:px-12 z-20 py-12 border-2 max-w-2xl [background:linear-gradient(white,white)_padding-box,linear-gradient(var(--gradient-angle),#fd4113_0,#fea912_20%,transparent_80%)_border-box;] supports-[color:oklch(0_0_0)]:[background:linear-gradient(white,white)_padding-box,linear-gradient(var(--gradient-angle),oklch(67.3%_0.273_33.6)_0,oklch(81.5%_0.193_72.5)_20%,transparent_80%)_border-box;] shadow-lg border-transparent lg:flex-auto h-fit rounded-xl"
          >
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
              <FormItem label={t`name`}>
                <Input {...register('name', { required: true })} type="text" autoComplete="name" />
              </FormItem>
              <FormItem label={t`company`}>
                <Input {...register('company')} type="text" autoComplete="organization" />
              </FormItem>
              <FormItem label="Email">
                <Input
                  {...register('email', { required: true })}
                  type="email"
                  autoComplete="email"
                />
              </FormItem>
              <FormItem label={t`phone`}>
                <Input {...register('phone', { required: true })} type="text" autoComplete="tel" />
              </FormItem>
            </div>
            <Button
              type="submit"
              theme="primary"
              disabled={!isValid || !recaptchaLoaded}
              className="justify-center mt-10 w-full"
            >
              {t`landing.contact_me`}
            </Button>
            {lang === 'ru' && (
              <p className="mt-4 text-xs leading-6 text-gray-500">
                Отправляя форму, вы соглашаетесь с{' '}
                <a
                  href="/doc/sellematics/ru/privacy_policy.pdf"
                  className="font-medium text-primary-600"
                >
                  политикой обработки персональных данных
                </a>
              </p>
            )}
          </form>
        </div>
      </div>
      <div
        className="absolute hidden sm:block inset-x-0 top-[calc(100%-13rem)] z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-45rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff5a39] to-[#fcc63c] opacity-20 sm:left-[calc(50%+12rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </Container>
  )
}

export default DemoContactForm
