import { FC, c } from 'lib/component-utils'
import Container from './Container'
import Heading from './Heading'
import Image, { StaticImageData } from 'next/image'
import { useUserContext } from 'lib/context'
import { HostType } from 'configs/theme'

import sellmonitorAvatarImage1 from '@/public/next/img/landing/avatar1.jpg'
import sellmonitorAvatarImage2 from '@/public/next/img/landing/avatar2.jpg'
import sellmonitorAvatarImage3 from '@/public/next/img/landing/avatar3.jpg'

import sebLogo from '@/public/next/img/landing/logos/seb.svg'
import befreeLogo from '@/public/next/img/landing/logos/befree.svg'
import bkLogo from '@/public/next/img/landing/brand-logos/bk_small_black.svg'
import { useIntl } from 'lib/intl-utils'
import ScrollArea from 'components/base/ScrollArea'
import { useState } from 'react'
import { PlayIcon } from '@heroicons/react/solid'

const contentByHost: Partial<
  Record<
    HostType,
    Partial<
      Record<
        string,
        {
          heading: string
          text: string
          author: { name: string; role: string; image?: StaticImageData }
        }[]
      >
    >
  >
> = {
  sellmonitor: {
    ru: [
      {
        heading:
          'С помощью Sellmonitor мне удалось закрыть свою потребность по отслеживанию стоков.',
        text: 'Теперь я знаю точно, сколько товаров хранится на складе у меня и у конкурентов, по этим данным я могу прогнозировать требуемое количество для следующей поставки. Благодаря своевременным отгрузкам оборачиваемость моих товаров выросла, и я не теряю выручку из-за того, что они заканчиваются. Интерфейс инструмента интуитивно понятен, мне и моим сотрудникам не потребовалось долгое обучение, я могу обратиться за поддержкой 24/7 и получить её от команды профессионалов.',
        author: {
          name: 'Елена Радионова',
          role: 'Индивидуальный предприниматель',
          image: sellmonitorAvatarImage1,
        },
      },
      {
        heading:
          'До знакомства с Sellmonitor я попробовал несколько сервисов аналитики, но высокую точность подсчета стоков и продаж смог обеспечить только этот.',
        text: 'Теперь я понимаю рыночную долю своих товаров в категории, и благодаря мониторингу конкурентов нахожу точки роста своего бизнеса. Например, с помощью правильно подобранных ключевых слов я улучшил качество контента своих карточек товара и наблюдал, как они поднимаются в поисковой выдаче.',
        author: {
          name: 'Станислав Сухарев',
          role: 'Индивидуальный предприниматель',
          image: sellmonitorAvatarImage2,
        },
      },
      {
        heading:
          'Благодаря разделу Поисковые запросы я оцениваю потенциал рынка по товарам/категориям и ищу перспективные ниши с низкой конкуренцией.',
        text: 'А с помощью расширения могу оценить какие продажи у первых в поисковой выдаче товаров, и сколько может принести высокая позиция. С аналитикой Sellmonitor я вывел свои товары в топ и увеличил выручку.',
        author: {
          name: 'Алексей Голубев',
          role: 'Индивидуальный предприниматель',
          image: sellmonitorAvatarImage3,
        },
      },
    ],
  },
  uzum: {
    uz: [
      {
        heading: 'Sellmonitor yordamida men zaxiralarni kuzatish ehtiyojimni qondira oldim.',
        text: "Endi men omborimda va raqobatchilarimda qancha mahsulot borligini aniq bilaman, bu ma'lumotlarga asoslanib, keyingi yetkazib berish uchun kerakli miqdorni prognoz qila olaman. O'z vaqtida yetkazib berish tufayli mahsulotlarimning aylanishi oshdi va ular tugab qolishi sababli daromadni yo'qotmayman. Asbob interfeysi intuitiv ravishda tushunarli, men va xodimlarimga uzoq o'qitish kerak bo'lmadi, men 24/7 qo'llab-quvvatlash uchun murojaat qila olaman va professional jamoadan yordam olaman.",
        author: {
          name: 'Elena Radionova',
          role: 'Yakka tartibdagi tadbirkor',
          image: sellmonitorAvatarImage1,
        },
      },
      {
        heading:
          "Sellmonitor bilan tanishishdan oldin men bir nechta tahlil xizmatlarini sinab ko'rdim, lekin zaxiralar va savdolarni aniq hisoblashni faqat shu ta'minladi.",
        text: "Endi men mahsulotlarimning marketpleys ulushini tushunaman va raqobatchilarni kuzatish orqali biznesimning o'sish nuqtalarini topaman. Masalan, to'g'ri tanlangan kalit so'zlar yordamida men mahsulot kartalarimning kontent sifatini yaxshiladim va ularning qidiruv natijalarida ko'tarilishini kuzatdim.",
        author: {
          name: 'Stanislav Suxarev',
          role: 'Yakka tartibdagi tadbirkor',
          image: sellmonitorAvatarImage2,
        },
      },
      {
        heading:
          "Qidiruv so'rovlari bo'limi tufayli men mahsulotlar/kategoriyalar bo'yicha marketpleys salohiyatini baholayman va past raqobatli istiqbolli bo'shliqlarni qidiraman.",
        text: "Va kengaytma yordamida men qidiruv natijalarida birinchi o'rinda turgan mahsulotlarning savdolarini baholay olaman va yuqori pozitsiya qancha daromad keltirishi mumkinligini bilaman. Sellmonitor tahlili bilan men mahsulotlarimni yuqori o'ringa olib chiqdim va daromadni oshirdim.",
        author: {
          name: 'Aleksey Golubev',
          role: 'Yakka tartibdagi tadbirkor',
          image: sellmonitorAvatarImage3,
        },
      },
    ],
    ru: [
      {
        heading:
          'С помощью Sellmonitor мне удалось закрыть свою потребность по отслеживанию стоков.',
        text: 'Теперь я знаю точно, сколько товаров хранится на складе у меня и у конкурентов, по этим данным я могу прогнозировать требуемое количество для следующей поставки. Благодаря своевременным отгрузкам оборачиваемость моих товаров выросла, и я не теряю выручку из-за того, что они заканчиваются. Интерфейс инструмента интуитивно понятен, мне и моим сотрудникам не потребовалось долгое обучение, я могу обратиться за поддержкой 24/7 и получить её от команды профессионалов.',
        author: {
          name: 'Елена Радионова',
          role: 'Индивидуальный предприниматель',
          image: sellmonitorAvatarImage1,
        },
      },
      {
        heading:
          'До знакомства с Sellmonitor я попробовал несколько сервисов аналитики, но высокую точность подсчета стоков и продаж смог обеспечить только этот.',
        text: 'Теперь я понимаю рыночную долю своих товаров в категории, и благодаря мониторингу конкурентов нахожу точки роста своего бизнеса. Например, с помощью правильно подобранных ключевых слов я улучшил качество контента своих карточек товара и наблюдал, как они поднимаются в поисковой выдаче.',
        author: {
          name: 'Станислав Сухарев',
          role: 'Индивидуальный предприниматель',
          image: sellmonitorAvatarImage2,
        },
      },
      {
        heading:
          'Благодаря разделу Поисковые запросы я оцениваю потенциал рынка по товарам/категориям и ищу перспективные ниши с низкой конкуренцией.',
        text: 'А с помощью расширения могу оценить какие продажи у первых в поисковой выдаче товаров, и сколько может принести высокая позиция. С аналитикой Sellmonitor я вывел свои товары в топ и увеличил выручку.',
        author: {
          name: 'Алексей Голубев',
          role: 'Индивидуальный предприниматель',
          image: sellmonitorAvatarImage3,
        },
      },
    ],
  },
}

function QuoteIcon(props: any) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

const VideoTestimonial: FC<
  { id: string; image: string; name: string; isOpened: boolean },
  'iframe'
> = ({ id: passedId, image, name, className, isOpened, ...rest }) => {
  const [oid, id] = passedId.split('_')

  return (
    <div className={c`shrink-0 relative select-none ${className}`}>
      {isOpened && (
        <iframe
          src={`https://vk.com/video_ext.php?oid=-${oid}&id=${id}`}
          allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
          allowFullScreen
          className={c`rounded-xl ring ring-1 ring-inset ring-black/5 w-[270px] h-[480px]`}
          {...rest}
        />
      )}
      {!isOpened && (
        <>
          <img
            src={`/next/img/landing/testimonials/${image}.jpg`}
            className="rounded-xl shrink-0 w-[270px] h-[480px] ring ring-inset ring-1 ring-black/5 object-cover"
          />
          <div
            className="absolute rounded-xl group hover:ring-2 cursor-pointer transition-shadow duration-200 hover:ring-gray-700 hover:ring-offset-2 inset-0"
            {...(rest as any)}
          >
            <div className="flex items-end justify-start h-full w-full">
              <span className="text-black bg-white text-base rounded-md px-3 py-1 font-medium m-4">
                {name}
              </span>
            </div>
            <div className="absolute inset-0 flex items-center justify-center">
              <PlayIcon className="w-16 h-16 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const sellmonitorVideoTestimonials = [
  //{ image: '2zOhdzyFZx8', name: 'Екатерина' },
  { id: '212594941_456239042', image: '3f_j49xizIU', name: 'Любовь' },
  { id: '212594941_456239053', image: 'hCgaR6ByJTw', name: 'Богдан' },
  { id: '212594941_456239044', image: 'PzXCy3r4sT4', name: 'Евгения' },
  { id: '212594941_456239063', image: 'aleksandr', name: 'Александр' },
  //{ image: 'd_QcpDcP0d4', name: 'Ксения' },
  { id: '212594941_456239046', image: 'DTmBsQrwDMo', name: 'Анна' },
  { id: '212594941_456239059', image: 'alisa', name: 'Алиса' },
  { id: '212594941_456239041', image: 'Oal0mCF1TwA', name: 'Наталья' },
  { id: '212594941_456239043', image: 'hklycbNwD5Q', name: 'Эльвира' },
]

const Testimonials: FC = () => {
  const ctx = useUserContext()
  const { lang } = useIntl()
  const [openedTestimonial, setOpenedTestimonial] = useState<string | null>(null)
  if (ctx.theme.hostType === 'sellscreen') return null
  const content = contentByHost[ctx.theme.hostType]![lang]!

  return (
    <Container>
      {lang === 'uz' ? (
        <Heading tagline="Odamlar biz haqimizda nima deyishadi" />
      ) : (
        <Heading tagline="Что люди говорят о нас" />
      )}
      <ScrollArea horizontal>
        <div className="mt-8 flex p-1 space-x-3">
          {sellmonitorVideoTestimonials.map((testimonial) => (
            <VideoTestimonial
              key={testimonial.id}
              id={testimonial.id}
              image={testimonial.image}
              name={testimonial.name}
              isOpened={openedTestimonial === testimonial.id}
              onClick={() => setOpenedTestimonial(testimonial.id)}
            />
          ))}
        </div>
      </ScrollArea>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 mx-auto mt-16 max-w-2xl sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
      >
        {content.map((testimonial, testimonialIndex) => (
          <li key={testimonialIndex}>
            <figure className="relative p-6 bg-white rounded-2xl border border-gray-200 shadow-lg shadow-slate-900/10">
              <QuoteIcon className="absolute top-6 left-6 fill-slate-100" />
              <blockquote className="relative">
                <p className="text-lg text-black">{testimonial.heading}</p>
                <p className="mt-4 text-base text-gray-600">{testimonial.text}</p>
              </blockquote>
              <figcaption className="flex relative justify-between items-center pt-6 mt-6 border-t border-slate-100">
                <div>
                  <div className="text-base font-display text-slate-900">
                    {testimonial.author.name}
                  </div>
                  <div className="mt-1 text-sm text-slate-500">{testimonial.author.role}</div>
                </div>
                {testimonial.author.image && (
                  <div className="overflow-hidden rounded-full bg-slate-50 shrink-0">
                    <Image
                      className="object-contain w-14 h-14"
                      src={testimonial.author.image}
                      alt=""
                      width={56}
                      height={56}
                    />
                  </div>
                )}
              </figcaption>
            </figure>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Testimonials
