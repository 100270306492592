import { CheckCircleIcon } from '@heroicons/react/outline'
import Button from 'components/base/Button'
import Link from 'components/base/Link'
import { HostType } from 'configs/theme'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useState } from 'react'
import Container from './Container'
import Heading from './Heading'
import { usePromoCodeContext } from './lib/promoCodeContext'
import { RadioGroup } from '@headlessui/react'
import { PromoCode } from 'lib/dto'
import { useTrackGoal } from 'lib/metrics'
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'

type Period = 30 | 90 | 180 | 360
type Plan = {
  description: string
  features: Record<string, string[]>
  mall?: string
  mallLogos: string[]
  theme: {
    bg: string
    fg: string
    glow: string
  }
  featured?: boolean
  pricing: Record<Period, { price: number; discountedPrice: number }>
}

const logoSizes: Record<string, string> = {
  wb: 'h-16',
  ozon: 'h-12',
  beru: 'h-16',
  uzum: 'h-[100px]',
}

const plansByHost: Partial<
  Record<
    HostType,
    {
      currency: string
      plans: Plan[]
    }
  >
> = {
  sellmonitor: {
    currency: 'RUB',
    plans: [
      {
        mall: 'ozon',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/ozon_big.png'],
        description: '',
        features: {
          ru: [
            'Сервис-партнёр Ozon — официальные данные по внешней аналитике',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Автоответы на отзывы',
            'Расчёт поставок по складам',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 13990, discountedPrice: 13990 },
          90: { price: 41970, discountedPrice: 37770 },
          180: { price: 83940, discountedPrice: 58740 },
          360: { price: 167880, discountedPrice: 92280 },
        },
      },
      {
        mall: 'wb',
        theme: {
          bg: 'bg-gray-900 lg:order-none order-first',
          fg: 'text-white',
          glow: '[box-shadow:0_0_130px_20px_theme(colors.purple.500)]',
        },
        featured: true,
        mallLogos: ['/img/mall_ico/wb_big.png'],
        description: '',
        features: {
          ru: [
            'Авторизованный сервис Wildberries',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Управление ставками по авторекламе',
            'Автоответы на отзывы',
            'Расчёт поставок по складам с учётом географии заказов',
            'SEO-оптимизация и генерация описания с использованием ИИ',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 13990, discountedPrice: 13990 },
          90: { price: 41970, discountedPrice: 37770 },
          180: { price: 83940, discountedPrice: 58740 },
          360: { price: 167880, discountedPrice: 92280 },
        },
      },
      {
        mall: 'beru',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/beru_white_big.png'],
        description: '',
        features: {
          ru: [
            'Сервис-партнёр Яндекс Маркета',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 9990, discountedPrice: 9990 },
          90: { price: 29970, discountedPrice: 26970 },
          180: { price: 59940, discountedPrice: 41940 },
          360: { price: 119880, discountedPrice: 65880 },
        },
      },
    ],
  },
  uzum: {
    currency: 'UZS',
    plans: [
      {
        mall: 'wb',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/wb_big.png'],
        description: '',
        features: {
          uz: [
            'Uzum Market bilan paket sifatida faqat sotib olinadi',
            'Wildberries rasmiy xizmati',
            'API asosidagi sotuvchi paneli',
            'Mahsulotlar, kategoriyalar, do‘konlar va brendlar bo‘yicha tashqi tahlil',
            'Qidiruv so‘rovlari tahlili',
            'Avtoreklama bo‘yicha stavkalarni boshqarish',
            'Sharhlarga avtomatik javob berish',
            'Buyurtmalar geografiyasini hisobga olgan holda omborlarga yetkazib berish hisobi',
            'SEO optimallashtirish va sun’iy intellekt yordamida tavsif yaratish',
            'Tahlil davri va chiqarilgan mahsulotlar soni bo‘yicha cheklovlarsiz',
            'Cheklanmagan Excel chiqarishlari',
          ],
          ru: [
            'Доступен только в пакете с Uzum Market',
            'Авторизованный сервис Wildberries',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Управление ставками по авторекламе',
            'Автоответы на отзывы',
            'Расчёт поставок по складам с учётом географии заказов',
            'SEO-оптимизация и генерация описания с использованием ИИ',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 1790000, discountedPrice: 1790000 },
          90: { price: 5370000, discountedPrice: 4832970 },
          180: { price: 10740000, discountedPrice: 7517940 },
          360: { price: 21480000, discountedPrice: 11814000 },
        },
      },
      {
        mall: 'uzum',
        theme: {
          bg: 'bg-gray-900 lg:order-none order-first',
          fg: 'text-white',
          glow: '[box-shadow:0_0_130px_20px_theme(colors.purple.500)]',
        },
        featured: true,
        mallLogos: ['/img/mall_ico/uzum_big.png'],
        description: '',
        features: {
          uz: [
            'Uzum Market rasmiy sherigi',
            'Mahsulotlar, kategoriyalar, do‘konlar va brendlar bo‘yicha tashqi tahlil',
            'Sun’iy intellekt yordamida tavsif yaratish',
            'Tahlil davri va chiqarilgan mahsulotlar soni bo‘yicha cheklovlarsiz',
            'Cheklanmagan Excel chiqarishlari',
          ],
          ru: [
            'Официальный партнер Uzum Market',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 633000, discountedPrice: 633000 },
          90: { price: 1899000, discountedPrice: 1709070 },
          180: { price: 3798000, discountedPrice: 2658540 },
          360: { price: 7596000, discountedPrice: 4177680 },
        },
      },
      {
        mall: 'ozon',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/ozon_big.png'],
        description: '',
        features: {
          uz: [
            'Uzum Market bilan paket sifatida faqat sotib olinadi',
            'Ozon sherik xizmati — rasmiy tashqi tahlil ma’lumotlari',
            'API asosidagi sotuvchi paneli',
            'Mahsulotlar, kategoriyalar, do‘konlar va brendlar bo‘yicha tashqi tahlil',
            'Qidiruv so‘rovlari tahlili',
            'Sharhlarga avtomatik javob berish',
            'Omborlarga yetkazib berish hisob-kitobi',
            'Sun’iy intellekt yordamida tavsif yaratish',
            'Tahlil davri va chiqarilgan mahsulotlar soni bo‘yicha cheklovlarsiz',
            'Cheklanmagan Excel chiqarishlari',
          ],
          ru: [
            'Доступен только в пакете с Uzum Market',
            'Сервис-партнёр Ozon — официальные данные по внешней аналитике',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Автоответы на отзывы',
            'Расчёт поставок по складам',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 1790000, discountedPrice: 1790000 },
          90: { price: 5370000, discountedPrice: 4832970 },
          180: { price: 10740000, discountedPrice: 7517940 },
          360: { price: 21480000, discountedPrice: 11814000 },
        },
      },
    ],
  },
  sellscreen: {
    currency: 'CNY',
    plans: [
      {
        mall: 'ozon',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/ozon_big.png'],
        description: '',
        features: {
          en: [
            'Official Ozon partner — competitor analytics data direct from marketplace',
            'API-based seller dashboard',
            'Product, category, store and brand analytics',
            'Search query analytics',
            'Review autoreplier',
            'AI description generation',
            'Unlimited analysis period',
            'Unlimited products',
            'Unlimited exports to Excel',
          ],
          zh: [
            'Ozon官方合作伙伴 — 来自市场的竞争对手分析数据',
            '基于API的卖家仪表板',
            '产品、类别、商店和品牌分析',
            '搜索查询分析',
            '评论自动回复',
            '人工智能内容生成',
            '分析时间不受限制',
            '无限产品',
            '导出至 Excel，最多 10,000 行',
          ],
        },
        pricing: {
          30: { price: 668, discountedPrice: 668 },
          90: { price: 2004, discountedPrice: 1674 },
          180: { price: 4008, discountedPrice: 2988 },
          360: { price: 8016, discountedPrice: 4776 },
        },
      },
      {
        mall: 'wb',
        theme: {
          bg: 'bg-gray-900 lg:order-none order-first',
          fg: 'text-white',
          glow: '[box-shadow:0_0_130px_20px_theme(colors.purple.500)]',
        },
        featured: true,
        mallLogos: ['/img/mall_ico/wb_big.png'],
        description: '',
        features: {
          en: [
            'Authorized Wildberries analytics service',
            'API-based seller dashboard',
            'Product, category, store and brand analytics',
            'Search query analytics',
            'Automated ad auction bidder',
            'Review autoreplier',
            'SEO optimization and description generation using AI',
            'Unlimited analysis period',
            'Unlimited products',
            'Unlimited exports to Excel',
          ],
          zh: [
            '授权的Wildberries分析服务',
            '基于API的卖家仪表板',
            '产品、类别、商店和品牌分析',
            '搜索查询分析',
            '自动化广告拍卖出价管理',
            '评论自动回复',
            '使用人工智能的SEO优化和内容生成',
            '分析时间不受限制',
            '无限产品',
            '导出至 Excel，最多 10,000 行',
          ],
        },
        pricing: {
          30: { price: 748, discountedPrice: 748 },
          90: { price: 2244, discountedPrice: 1884 },
          180: { price: 4488, discountedPrice: 3348 },
          360: { price: 8976, discountedPrice: 5376 },
        },
      },
      {
        mall: 'beru',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/beru_white_big.png'],
        description: '',
        features: {
          en: [
            'Official Yandex Market partner',
            'Product, category, store and brand analytics',
            'AI description generation',
            'Unlimited analysis period',
            'Unlimited products',
            'Unlimited exports to Excel',
          ],
          zh: [
            'Yandex Market官方合作伙伴',
            '产品、类别、商店和品牌分析',
            '人工智能内容生成',
            '分析时间不受限制',
            '无限产品',
            '导出至 Excel，最多 10,000 行',
          ],
        },
        pricing: {
          30: { price: 668, discountedPrice: 668 },
          90: { price: 2004, discountedPrice: 1674 },
          180: { price: 4008, discountedPrice: 2988 },
          360: { price: 8016, discountedPrice: 4776 },
        },
      },
    ],
  },
}

const contentByHost: Partial<
  Record<HostType, Partial<Record<string, { tagline: string; description: string }>>>
> = {
  sellmonitor: {
    ru: {
      tagline: 'Простой тариф, который подходит всем',
      description:
        'Неважно, новичок вы или уже давно продаёте на маркетплейсах, здесь есть всё, что вам нужно',
    },
  },
  uzum: {
    uz: {
      tagline: 'Hamma uchun mos keladigan oddiy tarif',
      description:
        "Siz yangi boshlovchi bo'lsangiz yoki allaqachon marketpleyslarda sotayotgan bo'lsangiz, bu yerda sizga kerak bo'lgan hamma narsa mavjud",
    },
    ru: {
      tagline: 'Простой тариф, который подходит всем',
      description:
        'Неважно, новичок вы или уже давно продаёте на маркетплейсах, здесь есть всё, что вам нужно',
    },
  },
  sellscreen: {
    en: {
      tagline: 'A simple plan for everyone',
      description:
        'Whether you are just starting or already are an experienced seller, this has everything you need',
    },
    zh: {
      tagline: '适合所有人的简单计划',
      description: '无论您是刚刚起步，还是已经是经验丰富的卖家，这里都有您需要的一切',
    },
  },
}

type PlanProps = {
  name?: string
  plan: Plan
  period: Period
  href: string
  featured?: boolean
}

const promoPriceFromPeriod = (
  tariffs: PromoCode['tariffs'][number] | undefined,
  period: Period
) => {
  if (!tariffs) return null
  return {
    30: tariffs.pricePerMonth,
    90: tariffs.pricePer3Months,
    180: tariffs.pricePer6Months,
    360: tariffs.pricePerYear,
  }[period]
}

const textPeriodFromPeriod = (period: Period) => {
  return {
    30: 'month',
    90: '3_months',
    180: '6_months',
    360: 'year',
  }[period]
}

const Plan: FC<PlanProps> = ({ name, plan, period, href, featured = false }) => {
  const ctx = useUserContext()
  const promoCode = usePromoCodeContext()
  const { t, lang, format } = useIntl()
  const trackGoal = useTrackGoal()
  const currency = plansByHost[ctx.theme.hostType]!.currency
  const { price, discountedPrice } = plan.pricing[period]
  const theme = plan.theme
  const discount =
    Math.floor(
      (1 -
        Math.min(
          discountedPrice,
          promoPriceFromPeriod(
            promoCode?.tariffs.find((tariff) => tariff.mall === (plan.mall ?? tariff.mall)),
            period
          ) ?? Infinity
        ) /
          price) /
        0.05
    ) * 0.05

  return (
    <div
      className={c`flex shrink grow-0 selection:bg-primary-700 max-w-sm overflow-hidden relative flex-col rounded-3xl px-6 sm:px-8 py-8 ${theme.bg} ${theme.fg} ${theme.glow}`}
    >
      {name && <h3 className="mt-5 text-lg text-white font-display">{name}</h3>}
      {promoCode?.tariffs.some((tariff) => tariff.mall === (plan.mall ?? tariff.mall)) && (
        <div className="absolute right-0 py-1 pl-3.5 pr-4 mt-2 font-medium text-black rounded-bl-3xl -top-2 bg-lime-300">
          {t`landing.with_promo_code`}
        </div>
      )}
      {plan.mallLogos.length > 0 && (
        <div className="flex mb-4 space-x-2">
          {plan.mallLogos.map((logo) => (
            <div key={logo} className="flex items-center space-x-4 w-full">
              <div className="h-16 w-full -mt-1">
                <img
                  src={logo}
                  alt=""
                  className={c`absolute top-[58px] -translate-y-1/2 left-8 ${
                    logoSizes[plan.mall as string]
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <p
        className="grid grid-cols-4 text-5xl font-medium transition-transform duration-300 font-display"
        style={{
          transform: `translateX(${-{ 30: '75', 90: '50', 180: '25', 360: '0' }[period]}%)`,
        }}
      >
        {([360, 180, 90, 30] as const).map((pricePeriod) => (
          <span
            key={pricePeriod}
            className={c`block w-max flex flex-col gap-y-1 transition-opacity relative duration-300 ${
              period === pricePeriod
            } opacity-100 | opacity-0`}
          >
            <span>
              {format.price(
                ((promoPriceFromPeriod(
                  promoCode?.tariffs.find((tariff) => tariff.mall === (plan.mall ?? tariff.mall)),
                  pricePeriod
                ) ?? plan.pricing[pricePeriod].discountedPrice) /
                  pricePeriod) *
                  30,
                {
                  currency: currency as string,
                }
              )}
              {discount !== 0 && (
                <span
                  className={c`absolute -top-2.5 -right-5 z-10 text-sm bg-red-500 rotate-6 rounded-full px-1.5 py-0.5 ${
                    period === pricePeriod
                  } opacity-100 | opacity-0`}
                >
                  {format.number(-discount, { maximumFractionDigits: 0, style: 'percent' })}
                </span>
              )}
            </span>
            <span
              className={c`text-base space-x-[5px] transition-opacity text-white opacity-80 duration-300 ${
                period === pricePeriod
              } opacity-100 | opacity-0`}
            >
              {period !== 30 && (
                <span>
                  {format.price(
                    promoPriceFromPeriod(
                      promoCode?.tariffs.find(
                        (tariff) => tariff.mall === (plan.mall ?? tariff.mall)
                      ),
                      pricePeriod
                    ) ?? plan.pricing[pricePeriod].discountedPrice,
                    {
                      currency: currency as string,
                    }
                  )}
                </span>
              )}
              <span className="text-[13px]">{t(`each_${textPeriodFromPeriod(period)}`)}</span>
            </span>
          </span>
        ))}
      </p>
      {plan.description.length > 0 && <p className="mt-2 text-base">{plan.description}</p>}
      <Button
        as={Link}
        href={href}
        onClick={() => {
          if (['wb', 'ozon', 'beru'].includes(plan.mall!)) {
            trackGoal(`landing-clicked-register-${plan.mall as 'wb' | 'ozon' | 'beru'}`)
          }
        }}
        theme="white"
        className={c`mt-6 justify-center !rounded-full !border-transparent ${!featured} !bg-transparent !text-white !border-white hover:!bg-white hover:!text-black`}
      >
        {t`landing.try_for_free`}
      </Button>

      <ul
        role="list"
        className={c`mt-10 flex flex-col gap-y-3 text-sm ${!featured} text-slate-200`}
      >
        {plan.features[lang]!.map((feature: string) => (
          <li key={feature} className="flex">
            <CheckCircleIcon className={c`w-5 h-5 shrink-0 ${!featured} text-slate-400`} />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

const Pricing: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const content = contentByHost[ctx.theme.hostType]![lang]!
  const plans = plansByHost[ctx.theme.hostType]!.plans
  const [period, setPeriod] = useState<Period>(30)

  return (
    <Container className="relative bg-slate-900">
      <a id="pricing" className="absolute -translate-y-16" />
      <Heading dark tagline={content.tagline} description={content.description} />
      <div
        className={c`relative max-w-2xl -mx-4 sm:mx-auto lg:-mx-8 lg:max-w-none xl:mx-0 ${
          ctx.theme.hostType === 'sellmonitor'
        } mt-36 | mt-32`}
      >
        <RadioGroup
          as="div"
          value={period}
          onChange={setPeriod}
          className={c`absolute z-10 grid grid-cols-4 w-full max-w-sm text-white -translate-x-1/2 border border-black/5 place-content-around rounded-3xl left-1/2 backdrop-blur bg-black/10 ${
            ctx.theme.hostType === 'sellmonitor'
          } -top-28 | -top-20`}
        >
          <div
            aria-hidden
            className={c`absolute py-3 transition-transform duration-300 bg-white text-white rounded-3xl z-[-1] select-none w-1/4`}
            style={{
              transform: `translateX(${{ 30: '0', 90: '100%', 180: '200%', 360: '300%' }[period]})`,
            }}
          >
            0
          </div>
          {([30, 90, 180, 360] as const).map((buttonPeriod) => (
            <RadioGroup.Option
              key={buttonPeriod}
              as="button"
              value={buttonPeriod}
              className={c`py-3 grow rounded-3xl transition-all duration-300 ${
                buttonPeriod === period
              } text-black font-medium`}
            >
              {t(`landing.pricing_period.${buttonPeriod}`)}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {ctx.theme.hostType === 'sellmonitor' && (
          <a
            target="_blank"
            rel="noreferrer"
            href="/installment-plan/"
            className="flex absolute inset-x-0 -top-12 z-10 justify-center items-center space-x-1 font-medium text-white"
          >
            <span>На годовой тариф доступна рассрочка</span>
            <ArrowNarrowRightIcon className="w-4 h-4" />
          </a>
        )}
        {/* <div className="absolute top-0 -translate-y-8 rounded-[40px] blur-[80px] left-1/2 -translate-x-1/2 bg-primary-500 w-[28rem] h-[36rem] z-[0]" /> */}
        <div className="flex flex-col gap-y-8 justify-evenly items-center lg:gap-x-8 lg:flex-row lg:items-stretch lg:gap-y-0">
          {plans.map((plan, i) => (
            <Plan key={i} plan={plan} featured={plan.featured} period={period} href="/signup/" />
          ))}
        </div>
        {ctx.theme.hostType === 'sellmonitor' && (
          <div>
            <div className="flex flex-col justify-center items-center mt-20 sm:flex-row">
              <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-4 mt-4 sm:grid-cols-3 sm:grid-rows-1">
                {[
                  { name: 'goods', size: 'h-12' },
                  { name: 'lamoda', size: 'h-14' },
                  { name: 'uzum', size: 'h-16' },
                ].map((mall) => (
                  <div key={mall.name} className="px-4 py-2 rounded-lg w-fit">
                    <img
                      src={`/img/mall_ico/${mall.name}_white_big.png`}
                      className={c`object-contain ${mall.size}`}
                      alt={`${mall.name} logo`}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-2 items-center mt-4 sm:ml-4 md:ml-8">
                <span className="font-medium text-white">от 4990 рублей</span>
                <Button
                  as={Link}
                  href="/signup/"
                  theme="white"
                  className="!rounded-full !border-transparent"
                >
                  Попробовать бесплатно
                </Button>
              </div>
            </div>
            <a
              href="https://sellematics.com/"
              target="_blank"
              rel="noreferrer"
              className="mt-8 sm:mt-6 text-white/65 px-4 text-center hover:text-white/80 inline-flex items-center justify-center gap-1 w-full"
            >
              Нужны ещё маркетплейсы? Есть в нашем корпоративном сервисе Sellematics{' '}
              <ArrowNarrowRightIcon className="size-6 sm:size-4" />
            </a>
          </div>
        )}
        {ctx.theme.hostType === 'sellscreen' && (
          <div className="flex flex-col justify-center items-center mt-20 sm:flex-row">
            <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-4 mt-4 sm:grid-cols-3 sm:grid-rows-1">
              {[
                { name: 'goods', size: 'h-12' },
                { name: 'lamoda', size: 'h-14' },
                { name: 'uzum', size: 'h-16' },
              ].map((mall) => (
                <div key={mall.name} className="px-4 py-2 rounded-lg w-fit">
                  <img
                    src={`/img/mall_ico/${mall.name}_white_big.png`}
                    className={c`object-contain ${mall.size}`}
                    alt={`${mall.name} logo`}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2 items-center mt-4 sm:ml-4 md:ml-8">
              <span className="font-medium text-white">
                {' '}
                {lang === 'en' ? 'from ¥278' : '从¥278起'}
              </span>
              <Button
                as={Link}
                href="/signup/"
                theme="white"
                className="!rounded-full !border-transparent"
              >
                {lang === 'en' ? 'Try for free' : '免费试用'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default Pricing
