import { ChartBarIcon, CloudDownloadIcon, CodeIcon } from '@heroicons/react/outline'
import Container from 'components/landing/Container'
import { FC } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const contentByLang = {
  ru: {
    eyebrow: 'Наши услуги',
    heading: 'Всё, что нужно для аналитики e\u2060-\u2060commerce',
    description: 'Используйте наши данные так, как это удобно вам',
    features: [
      {
        name: 'Сервис аналитики',
        description:
          'Здесь можно посмотреть подробную статистику по товарам, категориям, брендам и поисковым запросам, сделать выгрузки в Excel и воспользоваться инструментами',
        icon: ChartBarIcon,
      },
      {
        name: 'Кастомные выгрузки и дашборды',
        description:
          'Наши аналитики проведут исследование и подготовят отчёт, а при необходимости — сделают дашборд с обновляющимися данными',
        icon: CloudDownloadIcon,
      },
      {
        name: 'Доступ к API',
        description: 'Откроем доступ к данным через API и предоставим документацию',
        icon: CodeIcon,
      },
    ],
  },
  en: {
    eyebrow: 'Our services',
    heading: 'All you need for e\u2060-\u2060commerce analytics',
    description: 'Use our data the way you want',
    features: [
      {
        name: 'Analytics service',
        description:
          'Here you can see detailed stats across products, categories, brands and search queries, export data to Excel, and use our tools',
        icon: ChartBarIcon,
      },
      {
        name: 'Custom exports and dashboards',
        description:
          'Our analysts will research what interests you and make a report. If you want, we can also make a dashboard with refreshing data',
        icon: CloudDownloadIcon,
      },
      {
        name: 'API access',
        description: 'We will give you access to our data via an API and provide documentation',
        icon: CodeIcon,
      },
    ],
  },
} as const

const Services: FC = () => {
  const { t, lang } = useIntl()
  const content = contentByLang[lang as 'en' | 'ru']

  return (
    <Container className="!pt-0 overflow-hidden relative">
      <a id="features" className="absolute -translate-y-8" />
      <div className="max-w-2xl mx-auto lg:text-center">
        <h2 className="text-base font-medium leading-7 text-primary-600">{content.eyebrow}</h2>
        <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl [text-wrap:balance]">
          {content.heading}
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">{content.description}</p>
      </div>
      <div className="max-w-2xl mx-auto mt-12 sm:mt-16 lg:max-w-none">
        <dl className="grid max-w-xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {content.features.map((feature) => (
            <div
              key={feature.name}
              className="flex flex-col px-8 py-6 border border-gray-200 rounded-xl"
            >
              <dt className="flex items-center text-base font-medium leading-7 text-gray-900 gap-x-3">
                <feature.icon className="flex-none w-5 h-5 text-primary-600" aria-hidden="true" />
                {feature.name}
              </dt>
              <dd className="flex flex-col flex-auto mt-4 text-base leading-7 text-gray-600">
                <p className="flex-auto">{feature.description}</p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Container>
  )
}

export default Services
