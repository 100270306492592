import { FC, c } from 'lib/component-utils'
import { forwardRef } from 'react'

type Props = {
  disabled?: boolean
  hasError?: boolean
  placeLeft?: JSX.Element
  placeRight?: JSX.Element
}

const Input: FC<Props, 'input'> = (
  { disabled, hasError, placeLeft, placeRight, className, ...rest },
  ref
) => {
  return (
    <label
      className={c`flex rounded-md border bg-white border-gray-300 hover:border-gray-400 focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500 hover:focus-within:border-primary-500
        ${Boolean(
          hasError
        )} border-red-300 hover:border-red-400 focus-within:ring-red-500 focus-within:border-red-500 hover:focus-within:border-red-500
        ${Boolean(disabled)} !bg-gray-50 !border-gray-300 hover:!border-gray-300 focus-within:ring-0
        ${className}`}
    >
      {placeLeft && (
        <div className="relative flex items-center w-auto pl-3 pr-2 mr-px -ml-px text-gray-400 shrink-0">
          {placeLeft}
        </div>
      )}
      <input
        ref={ref}
        className={c`block w-full py-2 rounded-md text-sm border-none focus:border-none focus:outline-none focus:ring-0 text-black placeholder:text-gray-400 disabled:bg-gray-50 disabled:cursor-not-allowed
          ${Boolean(placeLeft)} pl-0 | pl-3
          ${Boolean(placeRight)} pr-0 | pr-3`}
        type="text"
        disabled={disabled}
        {...rest}
      />
      {placeRight && (
        <div className="relative flex items-center w-auto pl-2 pr-3 ml-px -mr-px text-gray-400 shrink-0">
          {placeRight}
        </div>
      )}
    </label>
  )
}

export default forwardRef(Input as any) as typeof Input
