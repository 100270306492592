import Button from 'components/base/Button'
import { FC } from 'lib/component-utils'

const YandexIntegration: FC = () => {
  return (
    <div className="text-base text-white bg-gradient-to-tr from-gray-900 to-gray-700 selection:bg-gray-700">
      <div className="w-full px-6 pt-12 mx-auto max-w-7xl sm:px-16 pb-14">
        <h2 className="text-2xl font-medium">Sellmonitor&#x202F;—&#x202F;партнёр Яндекс Маркета</h2>
        <div className="mt-4 space-y-0.5">
          <p>Наш сервис получает самые актуальные данные по маркетплейсу — проверено продавцами.</p>
          <p>
            Ещё не работаете с Яндекс Маркетом? Зарегистрируйтесь и получите 10 000 бонусов на
            продвижение.
          </p>
        </div>
        <Button
          as="a"
          href="https://partner.market.yandex.ru/welcome/partners?utm_source=partners&utm_medium=sellematics&utm_campaign=analytics&utm_content=button"
          target="_blank"
          rel="noreferrer"
          theme="primary"
          className="!bg-yellow-300 hover:brightness-105 focus:ring-offset-gray-900 focus:ring-yellow-300 !text-black mt-8"
        >
          Зарегистрироваться
        </Button>
      </div>
    </div>
  )
}

export default YandexIntegration
