import { CheckIcon } from '@heroicons/react/solid'
import Container from 'components/landing/Container'
import { FC } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const contentByLang = {
  ru: {
    eyebrow: 'Всё, что нужно',
    heading: 'Для брендов',
    description: 'Следите за конкурентами, контролируйте работу дистрибьюторов',
    features: [
      {
        name: 'Анализ объёма продаж',
        description: 'брендов-конкурентов по любым категориям за любой период',
      },
      {
        name: 'Контроль цены',
        description: 'каждого товара отдельно по дистрибьюторам',
      },
      {
        name: 'Анализ заполнения контента',
        description: 'карточек товара',
      },
      {
        name: 'Анализ представленности',
        description: 'товаров на страницах поисковой выдачи и категорий',
      },
    ],
  },
  en: {
    eyebrow: 'All you need',
    heading: 'For brands',
    description: "Monitor the competition, control the distributors' work",
    features: [
      {
        name: 'Sales volume analysis',
        description: 'for competitor brands — for any category or period',
      },
      {
        name: 'Pricing control',
        description: 'for every product, sepatately for each distributor',
      },
      {
        name: 'Content completeness analysis',
        description: 'for product pages',
      },
      {
        name: 'Placement analysis',
        description: 'for products in search results and category lists',
      },
    ],
  },
} as const

const ForBrands: FC = () => {
  const { t, lang } = useIntl()
  const content = contentByLang[lang as 'en' | 'ru']

  return (
    <Container className="!pt-px overflow-hidden relative">
      <div className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:pr-8 lg:pt-4">
          <div className="lg:max-w-lg">
            <h2 className="text-base font-semibold leading-7 text-primary-600">
              {content.eyebrow}
            </h2>
            <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              {content.heading}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">{content.description}</p>
            <dl className="max-w-xl mt-10 space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              {content.features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-medium text-gray-900">
                    <CheckIcon
                      className="absolute w-5 h-5 text-primary-500 left-1 top-1"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <img
          src={`/next/img/landing/sellematics/${lang}/categories_large.png`}
          alt=""
          className="w-[48rem] max-w-none z-20 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
          width={2432}
          height={1442}
        />
      </div>
      <div
        className="absolute hidden sm:block inset-x-0 top-[calc(100%-13rem)] z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-45rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff5a39] to-[#fcc63c] opacity-20 sm:left-[calc(50%+24rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </Container>
  )
}

export default ForBrands
