import { c, FC } from 'lib/component-utils'

type Props = {
  tagline: string
  description?: string
  dark?: boolean
}

const Heading: FC<Props> = ({ tagline, description, dark = false }) => {
  return (
    <div className={c`max-w-2xl mx-auto text-center ${dark} selection:bg-primary-800`}>
      <h2 className={c`text-3xl font-medium ${dark} text-gray-100 | text-gray-900`}>{tagline}</h2>
      {description && (
        <p className={c`mt-2 text-lg ${dark} text-white/80 | text-black/70`}>{description}</p>
      )}
    </div>
  )
}

export default Heading
