import { c, FC } from 'lib/component-utils'
import { forwardRef } from 'react'

type Props = {
  id: string
  noBorder?: boolean
  noRounding?: boolean
  autoplay?: boolean
  modestbranding?: boolean
}

const YouTube: FC<Props, 'iframe'> = (
  {
    id,
    autoplay,
    noBorder = false,
    noRounding = false,
    modestbranding = false,
    className,
    ...rest
  },
  ref
) => {
  return (
    <div className={c`aspect-w-16 aspect-h-9 ${className}`}>
      <iframe
        ref={ref}
        src={`https://www.youtube.com/embed/${id}?modestbranding=${modestbranding ? 1 : 0}&rel=0&${
          autoplay ? '&autoplay=1' : ''
        }`}
        className={c`${!noRounding} rounded-lg ${!noBorder} border border-gray-100`}
        {...rest}
      />
    </div>
  )
}

export default forwardRef(YouTube as any) as typeof YouTube
