import Container from 'components/landing/Container'
import { MallCode } from 'configs/malls'
import { FC, c } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const MallGroup: FC<{ groupName: string; groupNameClassName: string }> = ({
  groupName,
  groupNameClassName,
  children,
  className,
}) => {
  const { t } = useIntl()

  return (
    <div
      className={c`flex flex-col -mt-px -ml-px sm:px-8 pt-8 pb-9 first:border-t-0 last:border-b-0 sm:first:border sm:last:border border-y sm:border ${className}`}
    >
      <span
        className={c`text-lg w-fit font-medium bg-clip-text selection:text-black text-transparent bg-gradient-to-r ${groupNameClassName}`}
      >
        {t(`landing.mall_categories.${groupName}`)}
      </span>
      <div className="flex flex-wrap items-center justify-start gap-8 mt-9">{children}</div>
    </div>
  )
}

const MallImage: FC<{ mall: MallCode; description: string }> = ({
  mall,
  description,
  className,
}) => {
  return (
    <img
      className={className}
      src={`/next/img/landing/mall-logos-black/${mall}_black.svg`}
      alt={description}
    />
  )
}

const AvailableMalls: FC = () => {
  const { t, lang } = useIntl()

  return (
    <Container className="!pt-0">
      <p className="text-3xl font-semibold tracking-tight text-center text-gray-900 sm:text-4xl">{t`landing.we_have_data`}</p>
      <div className="grid grid-cols-12 mt-16">
        {lang === 'ru' && (
          <>
            <MallGroup
              groupName="marketplaces"
              className="col-span-12"
              groupNameClassName="from-purple-500 to-pink-500"
            >
              <MallImage mall="wb" description="Wildberries" className="h-[24px]" />
              <MallImage mall="ozon" description="Ozon" className="h-6" />
              <MallImage mall="beru" description="Яндекс.Маркет" className="h-[25px]" />
              <MallImage
                mall="goods"
                description="Мегамаркет"
                className="scale-[115%] origin-top-left h-7"
              />
              <MallImage mall="uzum" description="Uzum" className="h-[25px]" />
            </MallGroup>
            <MallGroup
              groupName="electronics"
              className="col-span-12"
              groupNameClassName="from-blue-500 to-cyan-500"
            >
              <MallImage mall="dns" description="DNS" className="h-6" />
              <MallImage mall="citilink" description="Ситилинк" className="h-7" />
              <MallImage mall="mvideo" description="М.Видео" className="h-7" />
              <MallImage mall="eldorado" description="Эльдорадо" className="h-5" />
              <MallImage mall="holodilnik" description="Холодильник.Ру" className="h-6" />
            </MallGroup>
            <MallGroup
              groupName="food"
              className="col-span-12"
              groupNameClassName="from-yellow-500 to-orange-500"
            >
              <MallImage mall="lavka" description="Яндекс Лавка" className="h-6" />
              <MallImage mall="samokat" description="Самокат" className="h-6" />
              <MallImage
                mall="lenta"
                description="Лента"
                className="relative h-6 -translate-y-0.5"
              />
              <MallImage mall={'magnit' as MallCode} description="Магнит" className="h-6" />
              <MallImage mall="perekrestok" description="Перекрёсток" className="h-6" />
              <MallImage mall="vprok" description="Vprok.ru Перекрёсток" className="h-7" />
              <MallImage
                mall="ozon_fresh"
                description="Ozon Fresh"
                className="scale-[120%] origin-bottom-left h-7"
              />
            </MallGroup>
            <MallGroup
              groupName="apothecary"
              className="col-span-12 sm:col-span-8"
              groupNameClassName="from-green-500 to-lime-500"
            >
              <MallImage mall="apteka" description="Apteka.ru" className="h-6" />
              <MallImage
                mall="zdravcity"
                description="Здравсити"
                className="relative h-5 translate-y-1"
              />
              <MallImage mall="goldapple" description="Золотое яблоко" className="relative h-6" />
              <MallImage
                mall="magnit_cosmetic"
                description="Магнит Косметик"
                className="relative h-6"
              />
            </MallGroup>
            <MallGroup
              groupName="other"
              className="col-span-12 sm:col-span-4"
              groupNameClassName="from-red-500 to-pink-500"
            >
              <MallImage mall="lamoda" description="Lamoda" className="h-6" />
              <MallImage mall="detmir" description="Детский мир" className="h-7" />
            </MallGroup>
          </>
        )}
        {lang === 'en' && (
          <>
            <MallGroup
              groupName="marketplaces"
              className="col-span-12"
              groupNameClassName="from-purple-500 to-pink-500"
            >
              <MallImage
                mall={'amazon' as MallCode}
                description="Amazon"
                className="translate-y-1 h-7"
              />
              <MallImage mall="noon" description="Noon" className="h-8" />
              <MallImage mall="wb" description="Wildberries" className="h-[22px]" />
              <MallImage mall="ozon" description="Ozon" className="h-6" />
              <MallImage mall="beru" description="Яндекс.Маркет" className="h-[25px]" />
            </MallGroup>
            <MallGroup
              groupName="food"
              className="col-span-12 sm:col-span-6"
              groupNameClassName="from-yellow-500 to-orange-500"
            >
              <MallImage mall="nana" description="Nana" className="h-8" />
              <MallImage mall="talabat" description="Talabat" className="h-6" />
            </MallGroup>
            <MallGroup
              groupName="other"
              className="col-span-12 sm:col-span-6"
              groupNameClassName="from-red-500 to-pink-500"
            >
              <MallImage mall="shopify" description="Shopify" className="h-9" />
            </MallGroup>
          </>
        )}
      </div>
    </Container>
  )
}

export default AvailableMalls
