import { FC } from 'lib/component-utils'
import Container from './Container'
import Heading from './Heading'
import Image, { StaticImageData } from 'next/image'
import { useUserContext } from 'lib/context'
import { HostType } from 'configs/theme'

import sellmonitorAvatarImage1 from '@/public/next/img/landing/avatar1.jpg'
import sellmonitorAvatarImage2 from '@/public/next/img/landing/avatar2.jpg'
import sellmonitorAvatarImage3 from '@/public/next/img/landing/avatar3.jpg'

import sebLogo from '@/public/next/img/landing/logos/seb.svg'
import befreeLogo from '@/public/next/img/landing/logos/befree.svg'
import bkLogo from '@/public/next/img/landing/brand-logos/bk_small_black.svg'
import { useIntl } from 'lib/intl-utils'

const contentByLang = {
  ru: [
    {
      heading:
        'Больше года в компании используем аналитику Sellematics и уже не представляем свою работу без этого сервиса.',
      text: 'Сервис предоставляет самые точные данные по площадке Wildberries. Это позволяет проводить конкурентный анализ и выстраивать стратегию продвижения на маркетплейсах. Специалисты сервиса проводят обучение как по использованию платформой, так и по нюансам работы с маркетплейсами.',
      author: {
        name: 'Василий Логинов',
        role: 'заместитель коммерческого директора «Белорусская косметика»',
        image: bkLogo,
      },
    },
    {
      heading: 'Сервис, без которого мы уже не представляем свою работу на маркетплейсах!',
      text: 'Работа с товаром по всем его направлениям (планирование ассортимента и глубины поставки, подсортировки, создание контента) становится более осознанной, и неминуемо ведет к улучшению коммерческих результатов деятельности на площадках. Особенно хочется отметить отзывчивость команды и стремление помочь клиентам, постоянный поиск новых инструментов, чтобы стать номером один на маркетплейсах.',
      author: {
        name: 'Анастасия Григорьева',
        role: 'Руководитель направления онлайн-партнеров befree',
        image: befreeLogo,
      },
    },
    {
      heading: 'Удобный и многофункциональный сервис, который решает множество вопросов.',
      text: 'Например мне, как специалисту по контенту, очень нравится функция числового анализа контента нашего бренда на площадках, что позволяет оперативно выявлять ошибки в карточках товара. Также специалисты Sellematics всегда на связи и помогут разобраться в любых вопросах. Рада, что у нас есть такой сервис!',
      author: {
        name: 'Анна Рыкова',
        role: 'Digital content specialist, Groupe Seb',
        image: sebLogo,
      },
    },
  ],
  en: [
    {
      heading:
        'We have been using Sellematics analytics in our company for more than a year and can no longer imagine our work without it.',
      text: "The service provides the most accurate data for the Wildberries marketplace. It allows us to conduct competitor analysis and build a promotion strategy on marketplaces. The service's specialists provide training both in using the platform and in the nuances of working with marketplaces.",
      author: {
        name: 'Vasily Loginov',
        role: 'Deputy Commercial Director, Belarusian Cosmetics',
        image: bkLogo,
      },
    },
    {
      heading: 'A service without which we can no longer imagine our work on marketplaces!',
      text: 'Working with products in all aspects (planning the product range and in-depth analysis of delivery, stock replenishment, content creation) becomes more informed, and inevitably leads to improved commercial results on the marketplaces. I would especially like to note the responsiveness of the team and the desire to help customers, the constant search for new tools to become a leader in e-commerce analytics.',
      author: {
        name: 'Anastasia Grigorieva',
        role: 'Head of Online Partners Department, befree',
        image: befreeLogo,
      },
    },
    {
      heading: 'Convenient and multifunctional service that solves many issues.',
      text: "For example, as a content specialist, I really like the function of numerical analysis of our brand content on the platforms, which allows us to quickly identify errors in product cards. Also, Sellematics specialists are always in touch and help sort out any issues in no time. I'm glad we have this service!",
      author: {
        name: 'Anna Rykova',
        role: 'Digital content specialist, Groupe Seb',
        image: sebLogo,
      },
    },
  ],
} as const

function QuoteIcon(props: any) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

const SellematicsTestimonials: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  if (ctx.theme.hostType === 'sellscreen') return null
  const content = contentByLang[lang as 'en' | 'ru']!

  return (
    <Container>
      <h2 className="text-3xl [text-wrap:balance] font-semibold tracking-tight text-center text-gray-900 sm:text-4xl">
        {t`landing.what_our_clients_say`}
      </h2>
      <ul
        role="list"
        className="grid max-w-2xl grid-cols-1 gap-6 mx-auto mt-16 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
      >
        {content.map((testimonial, testimonialIndex) => (
          <li key={testimonialIndex}>
            <figure className="relative p-6 bg-white border border-gray-200 shadow-lg rounded-2xl shadow-slate-900/10">
              <QuoteIcon className="absolute top-6 left-6 fill-slate-100" />
              <blockquote className="relative">
                <p className="text-lg text-black">{testimonial.heading}</p>
                <p className="mt-4 text-base text-gray-600">{testimonial.text}</p>
              </blockquote>
              <figcaption className="relative flex items-center justify-between pt-6 mt-6 border-t border-slate-100">
                <div>
                  <div className="text-base font-display text-slate-900">
                    {testimonial.author.name}
                  </div>
                  <div className="mt-1 text-sm text-slate-500">{testimonial.author.role}</div>
                </div>
                {testimonial.author.image && (
                  <div className="overflow-hidden rounded-full bg-slate-50 shrink-0">
                    <Image
                      className="object-contain h-14 w-14"
                      src={testimonial.author.image}
                      alt=""
                      width={56}
                      height={56}
                    />
                  </div>
                )}
              </figcaption>
            </figure>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default SellematicsTestimonials
