import { ArrowRightIcon } from '@heroicons/react/solid'
import Button from 'components/base/Button'
import { FC } from 'lib/component-utils'
import { useTrackGoal } from 'lib/metrics'
import Link from 'components/base/Link'

const AdIndexFirstPlace: FC = () => {
  const trackGoal = useTrackGoal()

  return (
    <div className="relative text-base text-black bg-gradient-to-tr from-white via-gray-50 to-gray-200 selection:!bg-lime-200">
      <div className="absolute hidden p-4 -translate-y-12 lg:block right-16 top-1/2 ring-4 rounded-xl ring-lime-300">
        <span className="font-semibold text-gray-600 text-[144px]">#1</span>
      </div>
      <div className="w-full px-6 pt-12 mx-auto max-w-7xl sm:px-16 pb-14">
        <h2 className="text-2xl font-medium">
          Sellmonitor&#x202F;—&#x202F;лучший по качеству сервис аналитики
          <br className="hidden lg:block" /> на маркетплейсах в 2023 году
        </h2>
        <div className="mt-4 space-y-0.5">
          <p className="max-w-[75ch]">
            Рейтинг AdIndex признал нас лучшими по совокупности функциональности, качества
            аналитических данных, инструментов и цены
          </p>
        </div>
        <div className="flex items-center mt-8 space-x-4">
          <Button
            as={Link}
            href="/signup/"
            onClick={() => trackGoal('landing-clicked-register')}
            theme="accent"
            className="w-fit transition-shadow h-[42px] hover:!bg-lime-300 hover:!brightness-105 hover:[box-shadow:0_0_48px_5px_theme(colors.lime.300)]"
          >
            Убедиться в качестве
          </Button>
          <Button
            as="a"
            href="https://adindex.ru/ratings/tech/2023/314602/#314627"
            target="_blank"
            rel="noreferrer"
            theme="gray"
            className="-mx-4"
          >
            <span>Посмотреть рейтинг</span>
            <ArrowRightIcon className="w-3.5 h-3.5" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AdIndexFirstPlace
