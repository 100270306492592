import Button from 'components/base/Button'
import { HostType } from 'configs/theme'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useTrackGoal } from 'lib/metrics'
import Link from 'components/base/Link'
import Container from './Container'

const contentByHost: Partial<
  Record<HostType, Partial<Record<string, { heading: string; description: string }>>>
> = {
  sellmonitor: {
    ru: {
      heading: 'Готовы попробовать Sellmonitor?',
      description: 'Получите бесплатный доступ к сервису и убедитесь во всём сами',
    },
  },
  uzum: {
    uz: {
      heading: "Sellmonitorni sinab ko'rishga tayyormisiz?",
      description: "Xizmatga bepul kirish huquqini oling va hammasini o'zingiz ko'ring",
    },
    ru: {
      heading: 'Готовы попробовать Sellmonitor?',
      description: 'Получите бесплатный доступ к сервису и убедитесь во всём сами',
    },
  },
  sellscreen: {
    en: {
      heading: 'Ready to try Sellscreen?',
      description: 'Get a free trial and see everything for youself',
    },
    zh: {
      heading: '准备好试用 Sellscreen了吗？',
      description: '获取免费试用版，亲身体验一切',
    },
  },
  sellematics: {
    en: {
      heading: 'Ready to try Sellscreen?',
      description: 'Get a free trial and see everything for youself',
    },
  },
}

const Cta: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const trackGoal = useTrackGoal()
  const content = contentByHost[ctx.theme.hostType]![lang]!

  return (
    <Container>
      <div className="isolate overflow-hidden relative px-6 py-24 -mx-2 text-center bg-gray-900 rounded-3xl shadow-2xl sm:mx-0 selection:bg-primary-800 sm:px-16">
        <h2 className="max-w-2xl mx-auto text-3xl sm:text-4xl font-semibold tracking-[-0.015rem] text-white">
          {content.heading}
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
          {content.description}
        </p>
        <div className="flex gap-x-6 justify-center items-center mt-10">
          <Button
            as={Link}
            theme="white"
            href="/signup/"
            onClick={() => trackGoal('landing-clicked-register')}
            className="rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            {t`landing.try_for_free`}
          </Button>
        </div>
        {ctx.theme.hostType === 'sellmonitor' && (
          <div className="mt-8 text-gray-300">
            <span className="block">Или узнать подробнее</span>
            <div className="inline-flex mt-2 space-x-4">
              <a
                target="_blank"
                rel="noreferrer"
                href="/wildberries/"
                className="font-medium hover:underline underline-offset-4"
              >
                Аналитика WB
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="/ozon/"
                className="font-medium hover:underline underline-offset-4"
              >
                Аналитика Ozon
              </a>
            </div>
          </div>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1024 1024"
          className="absolute top-[15%] left-1/2 -z-10 min-h-[64rem] min-w-[64rem] h-[64rem] w-[64rem] -translate-x-1/2"
          aria-hidden="true"
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
            fillOpacity="0.7"
          />
          <defs>
            <radialGradient
              id="827591b1-ce8c-4110-b064-7cb85a0b1217"
              cx={0}
              cy={0}
              r={1}
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(512 512) rotate(90) scale(512)"
            >
              <stop stopColor="#0062ff" />
              <stop offset={1} stopColor="#0062ff" stopOpacity={0} />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </Container>
  )
}

export default Cta
