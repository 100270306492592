import { Fragment } from 'react'
import { FC, c } from 'lib/component-utils'
import { Transition } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import Tooltip from 'components/base/Tooltip'

type Props = {
  label?: JSX.Element | string
  helpText?: string
  error?: string
  innerClassName?: string
}

const FormItem: FC<Props> = ({ label, helpText, error, className, children, innerClassName }) => {
  return (
    <div className={c`flex flex-col space-y-2 ${className}`}>
      <label className={c`flex flex-col w-full space-y-1`}>
        {label && (
          <span className="flex items-center w-full text-sm font-medium text-black">
            <span>{label}</span>
            {helpText && (
              <Tooltip sizing="text" content={helpText}>
                <QuestionMarkCircleIcon className="w-4 h-4 ml-1 text-gray-400" />
              </Tooltip>
            )}
          </span>
        )}
        <div className={c`${innerClassName}`}>{children}</div>
      </label>
      <Transition
        show={error ? error.length > 0 : false}
        as={Fragment}
        enter="transform ease-in-out duration-300 transition"
        enterFrom="-translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in duration-100 transition"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-2 opacity-0"
      >
        <p className="w-full text-sm text-red-600">{error}</p>
      </Transition>
    </div>
  )
}

export default FormItem
