import Button from 'components/base/Button'
import { FC } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const contentByLang = {
  ru: {
    heading: 'Sellmonitor — наш сервис для селлеров на маркетплейсах',
    paragraph1: 'Не нужны продвинутые инструменты для брендов и исследования от аналитиков?',
    paragraph2: 'Sellmonitor делает наши данные доступными даже для начинающих селлеров',
  },
  en: {
    heading: 'Sellscreen is our service for marketplace sellers',
    paragraph1: "Don't need advanced tools for brands and research from our analysts?",
    paragraph2: 'Sellscreen makes our data available even for novice sellers',
  },
} as const

const SellmonitorPlug: FC = () => {
  const { t, format, lang } = useIntl()
  const content = contentByLang[lang as 'en' | 'ru']

  return (
    <div className="relative overflow-hidden text-white bg-black selection:!bg-blue-600 isolate">
      <div className="w-full px-6 pt-12 mx-auto max-w-7xl sm:px-16 pb-14">
        <h2 className="text-2xl font-medium">{content.heading}</h2>
        <div className="mt-4 space-y-1 text-base">
          <p>{content.paragraph1}</p>
          <p>{content.paragraph2}</p>
        </div>
        <Button
          as="a"
          href="https://sellmonitor.com/?utm_source=partners&utm_medium=sellematics&utm_content=button"
          target="_blank"
          rel="noreferrer"
          theme="primary"
          className="!bg-gradient-to-tr from-sky-300 to-blue-300 hover:brightness-105 focus:ring-offset-gray-900 focus:ring-blue-300 !text-black mt-8"
        >
          {t`landing.interesting`}
        </Button>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
        className="absolute top-[-50%] left-[85%] -z-10 min-h-[64rem] min-w-[64rem] h-[64rem] w-[64rem] -translate-x-1/2"
        aria-hidden="true"
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient
            id="827591b1-ce8c-4110-b064-7cb85a0b1217"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(512 512) rotate(90) scale(512)"
          >
            <stop stopColor="#0062ff" />
            <stop offset={1} stopColor="#0062ff" stopOpacity={0} />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}

export default SellmonitorPlug
