import { FC, c } from 'lib/component-utils'
import * as This from '@radix-ui/react-scroll-area'
import { forwardRef } from 'react'

type Props = {
  vertical?: boolean
  horizontal?: boolean
}

const ScrollArea: FC<Props, typeof This.Root> = (
  { vertical = false, horizontal = false, children, ...rest },
  ref
) => {
  return (
    <This.Root className="w-full max-w-full h-full max-h-full" {...rest}>
      <This.Viewport
        ref={ref}
        className={c`w-full h-full ${horizontal} overscroll-x-contain ${vertical} overscroll-y-contain`}
      >
        {children}
      </This.Viewport>
      {horizontal && (
        <This.Scrollbar
          orientation="horizontal"
          className="flex w-full h-1.5 -mb-2 bg-gray-200 rounded-full"
        >
          <This.Thumb className="w-10 h-1.5 transition-colors ease-in-out bg-gray-400 rounded-full hover:bg-gray-500" />
        </This.Scrollbar>
      )}
      {vertical && (
        <This.Scrollbar
          orientation="vertical"
          className="flex w-[7px] relative z-20 mr-0.5 h-full bg-transparent rounded-full data-[state=visible]:animate-fade-in data-[state=hidden]:animate-fade-out"
        >
          <This.Thumb className="!w-[7px] py-0.5">
            <div className="w-full h-full rounded-full transition-colors ease-in-out bg-black/40 hover:bg-black/50"></div>
          </This.Thumb>
        </This.Scrollbar>
      )}
      <This.Corner />
    </This.Root>
  )
}

export default forwardRef(ScrollArea as any) as typeof ScrollArea
