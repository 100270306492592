import {
  CalculatorIcon,
  CalendarIcon,
  CheckCircleIcon,
  DatabaseIcon,
  ReceiptTaxIcon,
  SparklesIcon,
  SupportIcon,
} from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import Container from './Container'
import Heading from './Heading'

const features = [
  {
    name: 'Качественные данные',
    description:
      'Наши данные отвечают строгим требованиям крупных компаний, с которыми мы работаем',
    icon: DatabaseIcon,
    class: 'bg-gray-500',
  },
  {
    name: 'Работаем с 2019 года',
    description: 'А значит, собираем данные дольше всех и показываем самую полную картину',
    icon: CalendarIcon,
    class: 'bg-red-500',
  },
  {
    name: 'Единый тариф',
    description:
      'Даём полный доступ ко всем функциям. Когда ваш бизнес взлетит, вам не придётся переходить на тариф за миллион рублей в год',
    icon: CalculatorIcon,
    class: 'bg-blue-500',
  },
  {
    name: 'Нет ограничений',
    description:
      'Сколько осталось генераций описания в этом месяце? Сколько угодно! А можно посмотреть данные за 2019 год? Да, и за любой период!',
    icon: CheckCircleIcon,
    class: 'bg-green-500',
  },
  {
    name: 'Быстрый и удобный сервис',
    description:
      'Sellmonitor нравится пользователям и получает награды. Может, это и не повысит ваши продажи, но им просто приятно пользоваться ;)',
    icon: SparklesIcon,
    class: 'bg-yellow-300',
  },
  {
    name: 'Поддержка в любой момент',
    description:
      'Мы всегда на связи — если у вас есть вопросы или что-то не получается, просто нажмите на кнопку внизу или напишите нам в Телеграме',
    icon: SupportIcon,
    class: 'bg-orange-500',
  },
]

const Advantages: FC = () => {
  const ctx = useUserContext()
  if (ctx.theme.hostType === 'sellscreen') return null

  return (
    <Container>
      <Heading
        tagline="Почему нас выбирают?"
        description="Наша миссия — создать сервис аналитики мирового уровня, поэтому мы всегда стремимся быть
          на шаг впереди"
      />
      <ul
        role="list"
        className="grid max-w-2xl grid-flow-col grid-rows-6 gap-10 px-2 mx-auto mt-16 text-base sm:mt-20 sm:grid-rows-3 md:gap-y-10 lg:gap-y-0 lg:max-w-none lg:grid-rows-2"
      >
        {features.map((feature) => (
          <li
            key={feature.name}
            className="relative lg:[&:nth-child(-n+4)]:border-r lg:[&:nth-child(-n+4)]:pr-6 group lg:even:pt-8"
          >
            <feature.icon className="w-8 h-8" />
            <h3 className="mt-6 font-medium text-gray-900">{feature.name}</h3>
            <p className="mt-2 text-gray-700">{feature.description}</p>
            <div
              className={c`absolute w-4 h-4 rounded-full top-2 lg:group-even:top-10 left-2 blur ${feature.class}`}
            />
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Advantages
