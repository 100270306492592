import { useEffect, useState } from 'react'
import Image, { StaticImageData } from 'next/image'
import { Tab } from '@headlessui/react'

import backgroundImage from '@/public/next/img/landing/background-features.jpg'

import sellscreenEnBusiness from '@/public/next/img/landing/en/business.png'
import sellscreenEnProductList from '@/public/next/img/landing/en/product_list.png'
import sellscreenEnCategories from '@/public/next/img/landing/en/categories.png'
import sellscreenEnSearchQueries from '@/public/next/img/landing/en/search_queries.png'

import sellscreenZhBusiness from '@/public/next/img/landing/zh/business.png'
import sellscreenZhProductList from '@/public/next/img/landing/zh/product_list.png'
import sellscreenZhCategories from '@/public/next/img/landing/zh/categories.png'
import sellscreenZhSearchQueries from '@/public/next/img/landing/zh/search_queries.png'

import sellmonitorBusiness from '@/public/next/img/landing/business_large.png'
import sellmonitorProductList from '@/public/next/img/landing/product_list_large.png'
import sellmonitorCategories from '@/public/next/img/landing/categories_large.png'
import sellmonitorSearchQueries from '@/public/next/img/landing/search_queries_large.png'

import sellmonitorUzProductList from '@/public/next/img/landing/uz/product_list.png'
import sellmonitorUzCategories from '@/public/next/img/landing/uz/categories.png'
import sellmonitorUzSearchQueries from '@/public/next/img/landing/uz/search_queries.png'
import sellmonitorUzProductListRu from '@/public/next/img/landing/uz/product_list_ru.png'
import sellmonitorUzCategoriesRu from '@/public/next/img/landing/uz/categories_ru.png'

import Container from './Container'
import { c, FC } from 'lib/component-utils'
import Heading from './Heading'
import { HostType } from 'configs/theme'
import { useUserContext } from 'lib/context'
import { useTrackGoal } from 'lib/metrics'
import { useIntl } from 'lib/intl-utils'

const contentByHost: Partial<
  Record<
    HostType,
    Partial<
      Record<
        string,
        {
          tagline: string
          description: string
          features: { title: string; description: string; image: StaticImageData }[]
        }
      >
    >
  >
> = {
  sellmonitor: {
    ru: {
      tagline: 'Как увеличить продажи и снизить издержки?',
      description:
        'Мы предоставляем все необходимые инструменты, чтобы вы могли сфокусироваться на росте вашего бизнеса',
      features: [
        {
          title: 'Внутренняя аналитика',
          description:
            'Контролируйте развитие своего бизнеса, находите успешные и неликвидные товары, сокращайте расходы, получайте советы по поставкам, отвечайте на отзывы автоматически',
          image: sellmonitorBusiness,
        },
        {
          title: 'Товары',
          description:
            'Анализируйте продажи конкурентов, отслеживайте позицию в поиске, контролируйте отзывы и контент, визуализируйте метрики с помощью графиков',
          image: sellmonitorProductList,
        },
        {
          title: 'Категории',
          description:
            'Сравнивайте категории и отслеживайте изменения и сезонность, находите тренды и перспективные ниши с меньшей конкуренцией',
          image: sellmonitorCategories,
        },
        {
          title: 'Поисковые запросы',
          description:
            'Отслеживайте популярные и трендовые запросы, чтобы заметить будущий бестселлер раньше других. Выводите свои товары в топ, используя ключевые слова, которые ищут люди',
          image: sellmonitorSearchQueries,
        },
      ],
    },
  },
  uzum: {
    uz: {
      tagline: 'Savdolarni qanday oshirish va xarajatlarni qanday kamaytirish mumkin?',
      description:
        "Biz sizga biznesingizni o'sishiga e'tibor qaratishingiz uchun kerakli barcha vositalarni taqdim etamiz",
      features: [
        // {
        //   title: 'Ichki tahlil',
        //   description:
        //     'Biznesingizning rivojlanishini nazorat qiling, muvaffaqiyatli va sotilmaydigan mahsulotlarni aniqlang, xarajatlarni qisqartiring, yetkazib berish bo‘yicha maslahatlar oling, sharhlarga avtomatik javob bering',
        //   image: sellmonitorBusiness,
        // },
        {
          title: 'Mahsulotlar',
          description:
            'Raqqobatchilarning sotuvlarini tahlil qiling, qidiruvdagi o‘rningizni kuzatib boring, sharh va kontentni boshqaring, grafiklar yordamida metrikalarni vizuallashtiring',
          image: sellmonitorUzProductList,
        },
        {
          title: 'Kategoriyalar',
          description:
            'Kategoriyalarni solishtiring va o‘zgarishlar hamda mavsumiylikni kuzatib boring, kamroq raqobatga ega istiqbolli nişalarni toping',
          image: sellmonitorUzCategories,
        },
        {
          title: 'Qidiruv so‘rovlari',
          description:
            'Rossiya marketing maydonchalaridagi ommabop va trenddagi so‘rovlarni kuzatib boring, kelajakdagi eng yaxshi sotuvchini boshqalardan oldin aniqlang',
          image: sellmonitorUzSearchQueries,
        },
      ],
    },
    ru: {
      tagline: 'Как увеличить продажи и снизить издержки?',
      description:
        'Мы предоставляем все необходимые инструменты, чтобы вы могли сфокусироваться на росте вашего бизнеса',
      features: [
        {
          title: 'Товары',
          description:
            'Анализируйте продажи конкурентов, отслеживайте позицию в поиске, контролируйте отзывы и контент, визуализируйте метрики с помощью графиков',
          image: sellmonitorUzProductListRu,
        },
        {
          title: 'Категории',
          description:
            'Сравнивайте категории и отслеживайте изменения и сезонность, находите тренды и перспективные ниши с меньшей конкуренцией',
          image: sellmonitorUzCategoriesRu,
        },
        {
          title: 'Поисковые запросы',
          description:
            'Отслеживайте популярные и трендовые запросы на российских маркетплейсах, чтобы заметить будущий бестселлер раньше других',
          image: sellmonitorSearchQueries,
        },
      ],
    },
  },
  sellscreen: {
    en: {
      tagline: 'How to increase sales and lower costs?',
      description: 'We provide all the required tools so you can focus on growing your business',
      features: [
        {
          title: 'Internal analytics',
          description:
            "Monitor your business's growth, identify bestsellers and slow-moving items, cut costs, receive supply chain advice, and automatically respond to reviews",
          image: sellscreenEnBusiness,
        },
        {
          title: 'Product analytics',
          description:
            'Track sales data, monitor search placement, manage your reviews and content, visualize multiple metrics across time with powerful charts',
          image: sellscreenEnProductList,
        },
        {
          title: 'Category analytics',
          description:
            'See how different categories relate to each other and change over time, discover trends, seasonality, and untapped niches with less competition',
          image: sellscreenEnCategories,
        },
        {
          title: 'Search query analytics',
          description:
            'See popular and trending search keywords to identify potential bestsellers. Improve your search placement by using words people are looking for and validate the results',
          image: sellscreenEnSearchQueries,
        },
      ],
    },
    zh: {
      tagline: '如何提高销售额并降低成本？',
      description: '我们提供所有必要的工具，让您专注于业务增长',
      features: [
        {
          title: '内部分析',
          description:
            '监控您的业务增长，识别畅销品和滞销产品，削减成本，获得供应链建议，并自动回应评论',
          image: sellscreenZhBusiness,
        },
        {
          title: '产品分析',
          description:
            '跟踪销售数据、监控搜索位置、管理评论和内容，并通过功能强大的图表可视化跨时间的多个指标',
          image: sellscreenZhProductList,
        },
        {
          title: '分类分析',
          description:
            '了解不同类别之间的关系和随时间的变化，发现趋势、季节性和竞争较少的未开发利基市场',
          image: sellscreenZhCategories,
        },
        {
          title: '搜索查询分析',
          description:
            '查看热门和趋势搜索关键词，识别潜在畅销书。通过使用人们正在搜索的词来改进搜索位置，并验证搜索结果',
          image: sellscreenZhSearchQueries,
        },
      ],
    },
  },
}

export const PrimaryFeatures: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const trackGoal = useTrackGoal()
  const { features, tagline, description } = contentByHost[ctx.theme.hostType]![lang]!

  const [tabOrientation, setTabOrientation] = useState('horizontal')

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <Container id="features" className="overflow-hidden relative pt-20 pb-28 bg-blue-600 sm:py-32">
      <a id="features" className="absolute -translate-y-16" />
      <Image
        className="absolute top-1/2 left-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
        unoptimized
      />
      <div className="relative">
        <Heading dark tagline={tagline} description={description} />
        <Tab.Group
          as="div"
          className="grid grid-cols-1 gap-y-2 items-center pt-10 mt-16 selection:bg-primary-800 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="flex overflow-x-auto pb-4 -mx-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="flex relative z-10 gap-x-4 px-4 whitespace-nowrap sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={c`group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6 ${
                        selectedIndex === featureIndex
                      } bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10 | hover:bg-white/10 lg:hover:bg-white/5`}
                    >
                      <h3>
                        <Tab
                          className={c`font-medium text-lg focus:outline-none ${
                            selectedIndex === featureIndex
                          } text-blue-600 lg:text-white | text-blue-100 hover:text-white lg:text-white`}
                          onClick={() => {
                            if (featureIndex !== 0)
                              trackGoal(
                                `landing-features-clicked-${(featureIndex + 1) as 2 | 3 | 4}`
                              )
                          }}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={c`mt-2 hidden text-sm lg:block ${
                          selectedIndex === featureIndex
                        } text-white | text-blue-100 group-hover:text-white`}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel
                    key={feature.title}
                    unmount={false}
                    className="focus-visible:outline-none"
                  >
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <Image
                        unoptimized
                        className="w-full"
                        src={feature.image}
                        alt=""
                        priority
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </div>
    </Container>
  )
}
