import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import Button from 'components/base/Button'
import Container from 'components/landing/Container'
import { FC, c } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'
import { useState } from 'react'

const BrandImage: FC<{ name: string; description: string }> = ({
  name,
  description,
  className,
}) => {
  return (
    <img
      className={c`${className}`}
      src={`/next/img/landing/brand-logos/${name}_black.svg`}
      alt={description}
    />
  )
}

const WeWorkWith: FC = () => {
  const { t } = useIntl()
  const [expanded, setExpanded] = useState(false)

  return (
    <Container className="!pb-0 relative">
      <h2 className="text-3xl font-semibold tracking-tight text-center text-gray-900 sm:text-4xl">
        {t`landing.already_working_with_us`}
      </h2>
      <div
        className={c`mt-20 transition-[max-height] duration-500 ease-in-out overflow-hidden ${expanded} max-h-[992px] sm:max-h-[896px] | max-h-44 sm:max-h-56`}
      >
        <div
          className={c`grid grid-cols-3 sm:grid-rows-[repeat(11,_minmax(0,_1fr))] sm:grid-cols-5 sm:grid-rows-[repeat(7,_minmax(0,_1fr))] place-items-center gap-x-8 gap-y-14`}
        >
          <BrandImage name="loreal" description="L'Oréal" className="h-6" />
          <BrandImage name="sokolov" description="SOKOLOV" className="h-6" />
          <BrandImage name="scarlett" description="Scarlett" className="h-6" />
          <BrandImage name="heinz" description="Kraft Heinz" className="h-6" />
          <BrandImage name="seb" description="Groupe Seb" className="h-20" />
          <BrandImage name="benetton" description="United Colors of Benetton" className="h-9" />
          <BrandImage name="nielsen" description="NielsenIQ" className="h-8" />
          <BrandImage name="johnson" description="SC Johnson" className="h-8" />
          <BrandImage name="colpal" description="Colgate-Palmolive" className="h-6" />
          <BrandImage name="jacobs" description="Jacobs" className="relative h-10 -translate-y-2" />
          <BrandImage name="pepsico" description="PepsiCo" className="h-6" />
          <BrandImage name="finn_flare" description="Finn Flare" className="h-6" />
          <BrandImage name="realme" description="realme" className="h-7" />
          <BrandImage name="nutrilak" description="Nutrilak" className="h-6" />
          <BrandImage name="kitfort" description="Kitfort" className="h-7" />
          <BrandImage name="megamarket" description="Мегамаркет" className="h-9" />
          <BrandImage name="nivea" description="NIVEA" className="h-14" />
          <BrandImage name="natura" description="Natura Siberica" className="h-8" />
          <BrandImage name="zolla" description="Zolla" className="h-7" />
          <BrandImage name="snowqueen" description="Снежная Королева" className="h-6" />
          <BrandImage name="ampm" description="AM·PM" className="h-6" />
          <BrandImage
            name="national_geographic"
            description="National Geographic"
            className="h-9"
          />
          <BrandImage name="naos" description="Naos" className="h-7" />
          <BrandImage name="bkcosmetic" description="Белорусская косметика" className="h-9" />
          <BrandImage name="yallahub" description="Yallahub" className="h-9" />
          <BrandImage name="channelengine" description="ChannelEngine" className="h-7" />
          <BrandImage name="iris" description="Iris Pharma" className="h-8" />
          <BrandImage name="mgcom" description="MGCom" className="h-6" />
          <BrandImage name="omd" description="OMD" className="h-10" />
          <BrandImage name="orby" description="Orby" className="h-7" />
          <BrandImage name="realweb" description="Realweb" className="h-6" />
          <BrandImage name="socialist" description="SOCIALIST" className="h-7" />
          <BrandImage name="tidy" description="Тайди" className="h-8" />
        </div>
      </div>
      <div
        className={c`flex items-center justify-center mt-8 ${expanded} sticky bottom-4 inset-x-0`}
      >
        <Button
          theme="gray"
          className={c`!text-primary-600 bg-white/50 backdrop-blur hover:!text-primary-500 !text-base gap-1 ${expanded} !border !border-black/10`}
          onClick={() => setExpanded((current) => !current)}
        >
          {expanded ? t`show_less` : t`show_more`}
          {expanded ? (
            <ChevronUpIcon className="w-5 h-5 translate-y-px" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 translate-y-px" />
          )}
        </Button>
      </div>
    </Container>
  )
}

export default WeWorkWith
