import { c, FC } from 'lib/component-utils'

const Container: FC = ({ className, children }) => {
  return (
    <section className={c`py-20 sm:py-32 ${className}`}>
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">{children}</div>
    </section>
  )
}

export default Container
