import { CheckCircleIcon } from '@heroicons/react/solid'
import { HostType } from 'configs/theme'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useTrackGoal } from 'lib/metrics'
import { useState } from 'react'
import Container from './Container'
import Heading from './Heading'
import { useIntl } from 'lib/intl-utils'

const title: Partial<Record<HostType, Partial<Record<string, string>>>> = {
  sellmonitor: { ru: 'Чем поможет Sellmonitor?' },
  uzum: { uz: 'Sellmonitor sizga qanday yordam beradi?' },
  sellscreen: { en: 'How will Sellscreen help?', zh: 'Sellscreen 有何帮助？' },
}

const cases: {
  style: string
  hosts: Partial<
    Record<HostType, Partial<Record<string, { name: string; action: string; cases: string[] }>>>
  >
}[] = [
  {
    style: 'bg-lime-300 text-black border-lime-400',
    hosts: {
      sellmonitor: {
        ru: {
          name: 'Новичку',
          action: 'Выйти на маркетплейсы',
          cases: [
            'Получить представление о состоянии рынка',
            'Найти нишу с высоким спросом и низкой конкуренцией',
            'Выбрать товары, которые будут продаваться',
            'Скопировать успешную стратегию у опытных продавцов',
          ],
        },
      },
      uzum: {
        uz: {
          name: 'Yangi sotuvchilarga',
          action: 'Marketpleyslarga chiqish',
          cases: [
            'Marketpleys holatini tushunish',
            'Yuqori talab va past raqobatli marketpleysni topish',
            'Yaxshi sotiladigan mahsulotlarni tanlash',
            'Tajribali sotuvchilarning muvaffaqiyatli strategiyasini nusxalash',
          ],
        },
        ru: {
          name: 'Новичку',
          action: 'Выйти на маркетплейсы',
          cases: [
            'Получить представление о состоянии рынка',
            'Найти нишу с высоким спросом и низкой конкуренцией',
            'Выбрать товары, которые будут продаваться',
            'Скопировать успешную стратегию у опытных продавцов',
          ],
        },
      },
      sellscreen: {
        en: {
          name: 'New sellers',
          action: 'Start your business',
          cases: [
            'Assess the state of the market',
            'Find a niche with high demand and low competition',
            'Choose products that will sell well',
            'Find and copy successful strategies from experienced sellers',
          ],
        },
        zh: {
          name: '新卖家',
          action: 'Start your business',
          cases: [
            '评估市场状况',
            '寻找需求大、竞争小的细分市场',
            '选择畅销的产品',
            '从有经验的卖家那里寻找并复制成功策略',
          ],
        },
      },
    },
  },
  {
    style: 'bg-gradient-to-tr from-primary-600 to-sky-500 text-white border-primary-600',
    hosts: {
      sellmonitor: {
        ru: {
          name: 'Действующему продавцу',
          action: 'Вырасти',
          cases: [
            'Вывести свои товары в топ выдачи с помощью SEO-оптимизации',
            'Расширить ассортиментную матрицу',
            'Масштабировать продажи и освоить новые площадки',
            'Вовремя заметить трендовый товар и успеть заработать на нём',
          ],
        },
      },
      uzum: {
        uz: {
          name: 'Amaldagi sotuvchilarga',
          action: 'O‘sish',
          cases: [
            'SEO optimizatsiyasi yordamida mahsulotlaringizni top qidiruv natijalariga chiqarish',
            'Mahsulotlar assortimentini kengaytirish',
            'Savdolarni kengaytirish va yangi marketpleyslarni o‘zlashtirish',
            'Trenddagi mahsulotlarni o‘z vaqtida aniqlash va ulardan daromad olish',
          ],
        },
        ru: {
          name: 'Действующему продавцу',
          action: 'Вырасти',
          cases: [
            'Вывести свои товары в топ выдачи с помощью SEO-оптимизации',
            'Расширить ассортиментную матрицу',
            'Масштабировать продажи и освоить новые площадки',
            'Вовремя заметить трендовый товар и успеть заработать на нём',
          ],
        },
      },
      sellscreen: {
        en: {
          name: 'Existing sellers',
          action: 'Grow the business',
          cases: [
            "Use SEO to improve your products' ranking",
            'Expand your product range',
            'Scale your sales and expand to new marketplaces',
            'Identify trending products in time to make a profit',
          ],
        },
        zh: {
          name: '现有卖家',
          action: 'Grow the business',
          cases: [
            '利用搜索引擎优化提高产品排名',
            '扩大产品范围',
            '扩大销售规模，拓展新市场',
            '及时发现流行产品，赚取利润',
          ],
        },
      },
    },
  },
  {
    style: 'bg-gradient-to-tr from-gray-900 to-indigo-900 text-white border-black',
    hosts: {
      sellmonitor: {
        ru: {
          name: 'Крупному бизнесу',
          action: 'Захватить рынок',
          cases: [
            'Видеть свою прибыль и другие метрики на удобном дэшборде',
            'Принимать взвешенные решения на основании точных данных',
            'Выгружать данные для использования в сторонних системах',
            'Автоматизировать ответы на отзывы для управления имиджем бренда',
          ],
        },
      },
      uzum: {
        uz: {
          name: 'Yirik bizneslar uchun',
          action: 'Marketpleysni egallash',
          cases: [
            'Foyda va boshqa ko‘rsatkichlarni qulay dashboard orqali kuzatish',
            'Aniq ma’lumotlar asosida o‘ylangan qarorlar qabul qilish',
            'Ma’lumotlarni tashqi tizimlarda foydalanish uchun yuklab olish',
            'Brend imidjini boshqarish uchun sharhlarga avtomatik javob berish',
          ],
        },
        ru: {
          name: 'Крупному бизнесу',
          action: 'Захватить рынок',
          cases: [
            'Видеть свою прибыль и другие метрики на удобном дэшборде',
            'Принимать взвешенные решения на основании точных данных',
            'Выгружать данные для использования в сторонних системах',
            'Автоматизировать ответы на отзывы для управления имиджем бренда',
          ],
        },
      },
      sellscreen: {
        en: {
          name: 'Large businesses',
          action: 'Control the market',
          cases: [
            'Make intelligent decisions using accurate data',
            "Control your brand's image by monitoring negative reviews",
            'Track your business metrics and compare them with competitors',
            'Export data for use in third-party systems',
          ],
        },
        zh: {
          name: '大型企业',
          action: 'Control the market',
          cases: [
            '利用准确数据做出明智决策',
            '通过监控负面评论控制品牌形象',
            '跟踪您的业务指标并与竞争对手进行比较',
            '导出数据供第三方系统使用',
          ],
        },
      },
    },
  },
]

const UseCases: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const trackGoal = useTrackGoal()
  const [selected, setSelected] = useState(0)
  const currentCase = cases[selected]

  return (
    <Container className="bg-gray-100">
      <Heading tagline={title[ctx.theme.hostType]![lang]!} />
      <div className="flex overflow-x-auto mx-auto mt-16 space-x-2 max-w-full text-lg font-medium lg:space-x-6 lg:text-2xl w-fit">
        {cases.map(($case, i) => {
          const content = $case.hosts[ctx.theme.hostType]![lang]!
          return (
            <button
              key={content.name}
              onClick={() => {
                if (i !== 0) trackGoal(`landing-use-cases-clicked-${(i + 1) as 2 | 3}`)
                setSelected(i)
              }}
              className={c`px-6 py-3 lg:px-12 lg:py-6 rounded-full border ${
                selected !== i
              } bg-white border-gray-200 text-gray-900 ${selected === i ? $case.style : ''}`}
            >
              {content.name}
            </button>
          )
        })}
      </div>
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 place-content-between mx-auto mt-16 lg:grid-rows-2 w-fit lg:grid-cols-2 lg:px-16">
        {currentCase.hosts[ctx.theme.hostType]![lang]!.cases.map(($case) => (
          <li key={$case} className="flex">
            <CheckCircleIcon className="w-6 h-6 translate-y-0.5 shrink-0 text-gray-700" />
            <span className="ml-4 text-xl">{$case}</span>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default UseCases
