import Button from 'components/base/Button'
import { FC } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const ExtensionPlug: FC = () => {
  const { t } = useIntl()

  return (
    <div className="relative overflow-hidden text-white [background:linear-gradient(180deg,#0000007f,#0000007f),linear-gradient(180deg,rgb(23,37,84),rgb(23,37,84))] bg-no-repeat bg-center selection:!bg-blue-600 isolate">
      <div className="w-full px-6 pt-12 mx-auto max-w-7xl sm:px-16 pb-14">
        <h2 className="text-2xl font-medium">Попробуйте бесплатное расширение Sellmonitor</h2>
        <div className="mt-4 space-y-1 text-base">
          <p className="max-w-[65ch]">
            Расширение покажет данные о продажах и выручке, рекламные позиции и ставки в поиске и
            многое другое — прямо на сайтах Wildberries и Ozon
          </p>
        </div>
        <Button
          as="a"
          target="_blank"
          rel="noreferrer"
          href="https://chromewebstore.google.com/detail/sellmonitor/nlldhlipbgolknjpcihjdobjjngeejag"
          theme="primary"
          className="!bg-gradient-to-tr from-sky-300 to-blue-300 hover:brightness-105 focus:ring-offset-gray-900 focus:ring-blue-300 !text-black mt-8"
        >
          {t`install_extension`}
        </Button>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
        className="absolute top-[-50%] left-[85%] -z-10 min-h-[64rem] min-w-[64rem] h-[64rem] w-[64rem] -translate-x-1/2"
        aria-hidden="true"
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient
            id="827591b1-ce8c-4110-b064-7cb85a0b1217"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(512 512) rotate(90) scale(512)"
          >
            <stop stopColor="#0062ff" />
            <stop offset={1} stopColor="#0062ff" stopOpacity={0} />
          </radialGradient>
        </defs>
      </svg>
      <div className="absolute top-12 right-60 hidden xl:block">
        <img
          src="/next/img/landing/extension.png"
          alt="Sellmonitor Extension"
          className="h-[468px] w-[251px] shadow-xl rounded-xl ring-1 ring-black/10"
        />
      </div>
      <div className="absolute -top-56 right-16 hidden xl:block">
        <img
          src="/next/img/landing/extension.png"
          alt="Sellmonitor Extension"
          className="h-[468px] w-[251px] shadow-xl rounded-xl ring-1 ring-black/10"
        />
      </div>
    </div>
  )
}

export default ExtensionPlug
