import Container from 'components/landing/Container'
import { FC } from 'lib/component-utils'
import { useIntl } from 'lib/intl-utils'

const stats = [
  { name: 'products', value: 1_000_000_000 },
  { name: 'stores', value: 1_800_000 },
  { name: 'brands', value: 3_100_000 },
  { name: 'reviews', value: 1_400_000_000 },
]

const contentByLang = {
  ru: {
    heading: 'Собираем данные с 2019 года',
    description: 'Покажем изменения и тренды из года в год',
  },
  en: {
    heading: 'Collecting data since 2019',
    description: 'See the changes and trends year-over-year',
  },
} as const

const Stats: FC = () => {
  const { t, format, lang } = useIntl()
  const content = contentByLang[lang as 'en' | 'ru']

  return (
    <Container className="!pt-12">
      <div className="max-w-2xl mx-auto lg:max-w-none">
        <div className="text-center">
          <h2 className="text-3xl font-semibold [text-wrap:balance] tracking-tight text-gray-900 sm:text-4xl">
            {content.heading}
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">{content.description}</p>
        </div>
        <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.name} className="flex flex-col gap-2 p-8 bg-gray-400/5">
              <dt className="text-base font-medium leading-6 text-gray-600">
                {t(`landing.stats.${stat.name}`)}
              </dt>
              <dd className="order-first text-5xl font-semibold tracking-tight text-gray-900">
                {format.number(stat.value, { notation: 'compact' })}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Container>
  )
}

export default Stats
